<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>

    <div class="available-sites-config-detail-content">
      <YkcDetailPlus class="box-card">
        <div slot="header" class="clearfix">
          <span>基础信息</span>
        </div>
        <YkcDetailItemPlus label="客户名称:">{{ detail.orgName || '——' }}</YkcDetailItemPlus>
        <YkcDetailItemPlus label="客户分类:">{{ detail.configTypeDesc || '——' }}</YkcDetailItemPlus>
        <YkcDetailItemPlus label="电站维度:">
          {{ scopeTypeFormat(stationScopeType) || '——' }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card">
        <div slot="header" class="clearfix">
          <span>价格信息</span>
        </div>
        <YkcDetailItemPlus label="价格类型:">
          {{ detail.priceTypeStr }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="合作时间:">
          {{ detail.startTime }}--{{ detail.endTime }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="电费优惠:">
          {{ detail.powerRate || '--' }}
          {{ detail.powerRateType === '2' ? '%' : '元/度' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="服务费优惠:" :labelWidth="65">
          {{ detail.serviceRate || '--' }}
          {{ detail.serviceRateType === '2' ? '%' : '元/度' }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
    </div>
    <div>
      <ykc-table
        v-if="['1', '2', '3'].includes(stationScopeType)"
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="电站列表">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { marketManage, bigCustomerPric } from '@/service/apis';

  export default {
    name: 'bigcustomerStrategyDetail',
    data() {
      return {
        detail: {},
        stationScopeType: '',
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        tableData: [],

        tableColumns: [],
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      this.getDetail();
    },
    mounted() {},
    methods: {
      scopeTypeFormat(val) {
        const scopeTypeMap = {
          1: '按商户',
          2: '按电站',
          3: '按电站分组',
          4: '全部电站',
        };
        return scopeTypeMap[val];
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        const reqParams = this.$route.query;
        bigCustomerPric.priceConfigDetail(reqParams).then(res => {
          console.log('StationIsConfigDetail', res);
          this.$nextTick(() => {
            this.detail = res || {};
            this.stationScopeType = res.data.stationScopeType;
            if (['1', '2', '3'].includes(this.stationScopeType)) {
              this.getTableList();
            }
          });
        });
      },

      /**
       * 详情--电站分页
       */
      getTableList() {
        const orgId = this.$route.query.id;
        const data = {
          orgId,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        marketManage.StationIsConfigDetailPage(data).then(res => {
          if (this.stationScopeType === '1') {
            this.tableColumns = [
              { label: '商户编码', prop: 'dataId' },
              { label: '商户名称', prop: 'dataName' },
              { label: '商户类型', prop: 'merchantType' },
            ];
          } else if (this.stationScopeType === '2') {
            this.tableColumns = [
              { label: '电站编码', prop: 'dataId' },
              { label: '电站名称', prop: 'dataName' },
              { label: '归属商户', prop: 'merchantName' },
              { label: '电站类型', prop: 'stationType' },
            ];
          } else if (this.stationScopeType === '3') {
            this.tableColumns = [
              { label: '电站分组', prop: 'dataName' },
              { label: '电站数量', prop: 'stationCount' },
            ];
          }
          this.$nextTick(() => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total;
            this.pageInfo.pageIndex = res.current;
          });
        });
      },
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.getTableList();
      },
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.getTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .available-sites-config-detail-content {
    padding-bottom: 5px;
    .box-card {
      margin: 5px 0;
      .title {
        color: inherit;
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
</style>
